<template>
<v-container>
    <v-layout row>
        <v-flex xs12>
            <v-card>
                <v-layout row justify-center>
                    <div>
                        <img height="250" width="333" v-bind:src="img" v-if="img != null"/>
                        <vue-web-cam width="100%" @click.native="onCapture" ref="webcam" 
                        :device-id="deviceId"
                         @started="onStarted" @stopped="onStopped" @error="onError" @cameras="onCameras"
                          @camera-change="onCameraChange"
                          v-bind:class="classVideo"></vue-web-cam>
                    </div>
                </v-layout>
                <v-layout row justify-center>
                    <v-btn type="button" color="info" @click="onCapture" :disabled="!devicesRunning" v-if="img == null">
                        <v-icon left dark>mdi-camera</v-icon>Capture Photo
                    </v-btn>
                    <v-btn type="button" color="info" @click="onCapture" v-if="img != null">
                        <v-icon left dark>mdi-refresh</v-icon>Retake
                    </v-btn>
                </v-layout>
            </v-card>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import {
    WebCam
} from "vue-web-cam";

import moment from "moment";

export default {
    data() {
        return {
            size: "sm",
            img: null,
            camera: null,
            devicesRunning: false,
            deviceId: null,
            devices: [],
            error: ""
        };
    },
    components: {
        "vue-web-cam": WebCam
    },
    created: function () {},
    methods: {
        stopStreamedVideo(videoElem) {
            let stream = videoElem.srcObject;
            if (stream) {
                let tracks = stream.getTracks();
                tracks.forEach(track => {
                    // stops the video track
                    track.stop();
                });
            }
            this.$refs.webcam.$refs.video.srcObject = null;
        },

        onCapture() {
            if (this.devicesRunning) {
                this.img = this.$refs.webcam.capture();
                const name =
                    moment().format("YYMD-hmsa") +
                    "_C" +
                    Math.random()
                    .toString(36)
                    .substring(2, 5);
                this.$emit("capture", {
                    id: null,
                    source: this.img,
                    name: name
                });
            }
        },
        onStarted(stream) {
            console.log("On Started Event", stream);
            this.devicesRunning = true;
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
            this.devicesRunning = false;
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            const stream = this.$refs.webcam.$refs.video;
            if (stream) {
                this.stopStreamedVideo(this.$refs.webcam.$refs.video);
            }
            this.$refs.webcam.start();
        },
        onError(error) {
            this.error = error;
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            console.log("On Camera Change Event", deviceId);
        }
    },
    computed: {
        device: function () {
            return this.devices.find(n => n.deviceId === this.deviceId);
        },
        classVideo: function() {
          return this.img != null? 'hide-video': ''
        }
    },
    watch: {
        camera: function (id) {
            this.error = id;
            this.deviceId = id;
        },
        devices: function () {
            // Once we have a list select the first one
            const back = this.devices.find(p => p.label.includes("back"));
            if (back) {
                this.camera = back.deviceId;
                this.deviceId = back.deviceId;
            } else {
                const [first, ...tail] = this.devices;
                if (first) {
                    this.camera = first.deviceId;
                    this.deviceId = first.deviceId;
                }
            }
        }
    }
};
</script>

<style>
video {
    height: 250px;
    width: auto;
}
</style><style>
.container {
    padding: 0;
}

.hide-video {
  display: none;
}
</style>
